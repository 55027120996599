<template>
  <v-menu>
    <template #activator="{ props: aProps }">
      <v-btn color="white" icon v-bind="aProps">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-card :width="340">
      <v-card-text class="pb-1">
        <span v-if="userNameStore.getUserName" class="text-subtitle-2">
          {{ userNameStore.getUserName }}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="text" block @click="logout">
          <span>Logout</span>
          <v-spacer />
          <v-icon color="primary">mdi-logout</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { useUserNameStore } from "~/stores/userName"
import { useAuth } from "~/.nuxt/imports"

const userNameStore = useUserNameStore()
const auth = useAuth()

const logout = async () => {
  await auth.logout()
}
</script>
