<template>
  <v-app>
    <v-app-bar color="primary" style="z-index: 1000">
      <v-row v-if="$vuetify.display.mdAndUp" justify="start" align="center" class="fill-width mx-2">
        <img style="width: 120px" alt="Logo" src="/logo.png" />
        <v-divider class="mx-4" color="white" vertical />
        <span class="font-weight-bold text-h5">FATIMA</span>
        <v-divider class="mx-4" color="white" vertical />
        <span class="text-overline text-subtitle-1">Fiscal Application Tic Mapping Article</span>
        <v-spacer />
        <v-tooltip location="start">
          <template #activator="{ props: aProps }">
            <v-btn
              v-if="store.authenticated"
              :disabled="$route.path === '/changelog'"
              icon
              v-bind="aProps"
              @click="navigateTo('/changelog')"
            >
              <v-icon>mdi-timeline-text-outline</v-icon>
            </v-btn>
          </template>
          View changelog
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props: aProps }">
            <v-btn
              v-if="store.authenticated && hasJurisdictionWithWriteAccess"
              :disabled="$route.path === '/notification-settings'"
              icon
              v-bind="aProps"
              @click="navigateTo('/notification-settings')"
            >
              <v-icon>mdi-bell</v-icon>
            </v-btn>
          </template>
          View notification settings
        </v-tooltip>
        <jurisdiction-select v-if="store.authenticated" style="min-width: 130px; max-width: 140px" class="mx-2" />
        <user-menu />
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-10">
        <slot v-if="$vuetify.display.mdAndUp" />
        <v-sheet v-else color="#F9FAFA" class="pa-4 text-center mx-auto" min-width="240" rounded width="100%">
          <v-icon class="mb-5" color="primary" icon="mdi-emoticon-sad-outline" size="112" />
          <h2 class="text-h5 mb-6">Window width is too small</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            The resolution of this browser window is too low to be able to use Fatima properly
            <br />
            Required minimum is
            <b>960px</b>
            but the current width is
            <b class="text-error">{{ width }}px</b>
          </p>
        </v-sheet>
      </v-container>
      <notification-snackbar />
    </v-main>
    <v-footer app>
      <v-row justify="center" align="center" class="fill-width">
        <span>
          <v-icon class="ml-2" color="primary" size="small">mdi-copyright</v-icon>
          {{ new Date().getFullYear() }}
        </span>
        <span class="mx-1">
          <v-icon class="ml-2" color="primary" size="small">mdi-television</v-icon>
          {{ clientVersion }}
          <v-icon class="ml-2" color="primary" size="small">mdi-server-network</v-icon>
          {{ serverVersion }}
        </span>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script setup lang="ts">
import JurisdictionSelect from "~/components/toolbar/JurisdictionSelect.vue"
import UserMenu from "~/components/toolbar/UserMenu.vue"
import { useRootStore } from "~/stores/store"
import { useJurisdictionStore } from "~/stores/jurisdiction"
import NotificationSnackbar from "~/components/lib/NotificationSnackbar.vue"

const store = useRootStore()
const jurisdictionStore = useJurisdictionStore()

const clientVersion = computed(() => {
  return serverVersion.value
})

const { width } = useDisplay()
const serverVersion = ref("unknown")

onBeforeMount(async () => {
  serverVersion.value = await $http.$get("/api/system/buildinfo")
})

const hasJurisdictionWithWriteAccess = computed((): boolean =>
  jurisdictionStore.getJurisdictions.some((it) => !it.readOnly)
)
</script>
