<template>
  <v-snackbar v-model="state" :color="type" :timeout="timeout" class="mb-10" :max-width="960">
    <v-row align="center">
      <v-col class="flex-grow-0">
        <v-icon>{{ icon }}</v-icon>
      </v-col>
      <v-col class="flex-grow-1">
        <v-row>
          <v-col>
            <span>{{ message }}</span>
            <v-chip v-if="jumpChange" class="ml-2" @click="jumpToChange">
              <v-icon start>mdi-calendar</v-icon>
              {{ formatDate(jumpChange.date.toDateString()) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template #actions>
      <v-btn icon class="mr-2" @click="state = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import type { Notification } from "~/types/notification"
import { NotificationType } from "~/types/notification"
import type { JumpChange } from "~/types/jumpChange"
import { formatDate } from "~/utils/dateUtils"
import { useNotificationStore } from "~/stores/notification"
import { useJumpChangeStore } from "~/stores/jumpChange"

const notificationStore = useNotificationStore()
const jumpChangeStore = useJumpChangeStore()

const type: Ref<string | null> = ref(null)

const icon = computed(() => {
  switch (type.value) {
    case NotificationType.SUCCESS:
      return "mdi-check-bold"
    case NotificationType.ERROR:
      return "mdi-alert-circle"
    case NotificationType.WARNING:
      return "mdi-alert"
    default:
      return "mdi-information"
  }
})

const timeout = computed(() => {
  if (type.value === NotificationType.ERROR) return 10000
  else return 5000
})

const state = ref(false)
const message: Ref<string | null> = ref(null)
const jumpChange: Ref<JumpChange | null> = ref(null)

watch(
  () => notificationStore.notification,
  (newValue: Notification | null) => {
    if (newValue !== null) {
      message.value = newValue.message
      type.value = newValue.type
      state.value = true
      jumpChange.value = newValue.jumpToChange ? newValue.jumpToChange : null
    } else {
      state.value = false
    }
  }
)

const jumpToChange = () => {
  if (jumpChange.value !== null) {
    jumpChangeStore.jumpChange = jumpChange.value
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-btn.text-primary) {
  color: unset !important;
}

.v-snack__wrapper {
  max-width: none;
  height: 100px;
}
</style>
