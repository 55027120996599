<template>
  <v-autocomplete
    v-model:search="input"
    :model-value="jurisdictionStore.selectedJurisdiction"
    :items="jurisdictionStore.getJurisdictions"
    density="compact"
    no-data-text="No countries available"
    item-value="jurisdiction"
    item-title="jurisdiction"
    hide-details
    prepend-inner-icon="mdi-earth"
    class="no-overflow"
    variant="outlined"
    auto-select-first
    :menu-props="{ closeOnContentClick: true }"
    @update:model-value="selectedJurisdictionChanged($event)"
  >
    <template #item="{ item }">
      <v-list-item class="cursor-pointer" @click="selectedJurisdictionChanged(item.title)">
        <div class="d-flex justify-space-between align-center">
          <span class="text-overline">{{ item?.raw?.jurisdiction }}</span>
          <v-icon v-if="item?.raw?.readOnly" color="primary" size="small" class="ml-2">mdi-eye-outline</v-icon>
        </div>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { useJurisdictionStore } from "~/stores/jurisdiction"

const jurisdictionStore = useJurisdictionStore()

const input = ref("")

const selectedJurisdictionChanged = (jurisdiction: string) => {
  if (jurisdiction) {
    jurisdictionStore.selectJurisdiction(jurisdiction)
    input.value = jurisdiction
  } else jurisdictionStore.jurisdictionNeedsToBeSelected = true
}
</script>
